<template>
  <div>
    <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none" style="margin-top: 70px; margin-bottom: 100px">
      <navbar-top-component></navbar-top-component>
      <navbar-bottom-component></navbar-bottom-component>

      <vue-element-loading :active="isLoading" spinner="bar-fade-scale" class="bg-sekunder" />

      <!-- donasi views -->
      <div class="container" v-if="isLoading == false">
        <div class="row">
          <div class="col">
            <div class="row mx-1 border p-2 mt-2" style="border-radius: 5px" v-for="index in donation" :key="index.id_donasi" @click="goDetail(index)">
              <div class="col">
                <div class="row fs14">
                  <div class="col font-weight-bold">
                    {{ index.campaign_title }}
                  </div>
                  <div class="col-auto">
                    <div class="border p-1 rounded px-2 bg-tersier" style="font-size: 10px" v-if="index.status == 'PENDING'">Pending</div>
                    <div class="border p-1 rounded px-2 bg-sekunder text-white" style="font-size: 10px" v-else-if="index.status == 'PAID'">Berhasil</div>
                    <div class="border p-1 rounded px-2 bg-secondary text-white" style="font-size: 10px" v-else-if="index.status == 'PROCESS'">Diproses</div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col fs12">
                    {{getTimeNow(index.created_at)}}
                  </div>
                  <div class="col text-right font-weight-bold fs14">
                    Rp. {{ Number(index.amount).toLocaleString("id") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavbarBottomComponent from "../../components/NavbarBottomComponent.vue";
import NavbarTopComponent from "../../components/NavbarTopComponent.vue";
import moment from "moment";
export default {
  name: "DonasiViews",
  components: { NavbarTopComponent, NavbarBottomComponent },

  data() {
    return {
      donation: null,
      isLoading: true
    };
  },

  beforeMount() {
    this.loadDataDonasi();
  },

  methods: {
    getTimeNow(timeNow) {
      return moment(timeNow).locale('id').fromNow();
    },
    
    async loadDataDonasi() {
      await axios
        .get(
          process.env.VUE_APP_API + "donation",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(resp => {
          this.isLoading = false;
          console.log(resp);
          if (resp.status == 200) {
            this.donation = resp.data.data;
          }
        });
    },

    goDetail(index) {
      if (index.status == "PENDING") {
        this.$router.push('/donation/' + index.id + '/confirm');
      }
    }
  }
};
</script>

<style></style>